import { contractAddresses } from "../config/config";
import { balanceOfZoom } from "./contractMethods";
import { nameOfContractOfZoom } from "./contractMethods";
import { tokenOfOwnerByIndexZoom } from "./contractMethods";
import { tokenUrisZoom } from "./contractMethods";
import { balanceOfZoomPolygon } from "./contractMethods";
import { nameOfContractOfZoomPolygon } from "./contractMethods";
import { tokenOfOwnerByIndexZoomPolygon } from "./contractMethods";
import { tokenUrisZoomPolygon } from "./contractMethods";
import { polygonContractAddresses } from "../config/config";

export const walletAddress = "0xD67eBAADB51a5866f592cf043ebAF64CA01216C9";

export async function getTokenData() {
  // balanceofAll
  const balanceOfAll = await balanceOfZoom(contractAddresses, walletAddress);
  //console.log(balanceOfAll, " balance of all");

  //tokenOfOwnerByIndex
  const tokens = await tokenOfOwnerByIndexZoom(balanceOfAll, walletAddress);
  //console.log(tokens, " tokens");

  //tokenuri
  const tokenUris = await tokenUrisZoom(tokens);
  //console.log(tokenUris, "token uris");
  // console.log({ count: tokenUris.length });

  return tokenUris;
}

export async function getTokenDataFromPolygon() {
  //balaceOf polygon
  const balanceOfAllPolygon = await balanceOfZoomPolygon(
    polygonContractAddresses,
    walletAddress
  );
  //console.log(balanceOfAllPolygon, " balance of all polygon");

  //name
  // const nameOfContractsPolygon = await nameOfContractOfZoomPolygon(
  //   polygonContractAddresses,
  //   walletAddress
  // );
  // console.log(nameOfContractsPolygon, " name of contracts Polygon");

  //tokenOfOwnerByIndex
  const tokensPolygon = await tokenOfOwnerByIndexZoomPolygon(
    balanceOfAllPolygon,
    walletAddress
  );
  //console.log(tokensPolygon, " tokens Polygon");

  //tokenuri
  const tokenUrisPolygon = await tokenUrisZoomPolygon(tokensPolygon);
  //console.log(tokenUrisPolygon, "token uris Polygon");

  return tokenUrisPolygon;
}

export async function getContractNames() {
  const ethereumContractNames = await nameOfContractOfZoom(
    contractAddresses,
    walletAddress
  );

  const polygonContractNames = await nameOfContractOfZoomPolygon(
    polygonContractAddresses,
    walletAddress
  );

  const contractNames = [...ethereumContractNames, ...polygonContractNames];

  return contractNames;
}

export async function getOnlyTheNamesOfContracts() {
  const namesOfContracts = await getContractNames();
  const collectionNames = [];
  for (let i = 0; i < namesOfContracts.length; i++) {
    collectionNames.push(namesOfContracts[i].contractName);
  }
  //mainnet and polygon contract names may be duplicated 
  //(the wallet has tokens from both chains from the same collection)
  //therefor we need to filter the name list to get the correct slider images and names
  const fileterdCollectionNames = [...new Set(collectionNames)];
  return fileterdCollectionNames;
}

export async function getFilteredNames(collectionNames) {
  let filteredNames = collectionNames.filter(
    (name) =>
      name === "Ether Cards Founder" ||
      name === "Girls, Robots, Dragons" ||
      name === "Toddlerpillars" ||
      name === "LaMelo Ball Collectibles" ||
      name === "Twisted Tree Frogs Massive"
  );
  filteredNames.unshift("CamelCoding x Galaxis");
  return filteredNames;
}

export async function getMetaData() {
  const ethereumTokenData = await getTokenData();
  const polygonTokenData = await getTokenDataFromPolygon();
  const tokenData = [...ethereumTokenData, ...polygonTokenData];
  const nameOfContracts = await getContractNames();

  let metas = {};
  for (let i = 0; i < tokenData.length; i++) {
    for (let z = 0; z < nameOfContracts.length; z++) {
      if (tokenData[i].contractAddress === nameOfContracts[z].contractAddress) {
        if (metas[nameOfContracts[z].contractName] == null) {
          let array = [];
          metas[nameOfContracts[z].contractName] = array;
        }
        metas[nameOfContracts[z].contractName].push(tokenData[i].tokenUri);
      }
    }
  }

  //console.log(metas, 'metas')
  return metas;
}
