import { Modal } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import closenew from '../../../assets/images/closenew.svg';
import { useParams } from "react-router-dom";

const BP1 = '@media (max-width: 899px)';
const BP2 = '@media (max-width: 719px)';

const sx = {
    root: {
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.6)',
        px: '125px',
        position: 'relative',
        pointerEvents: 'none',
        transition: 'all .3s',
        [BP1]: {
            px: '85px',
        },
        [BP2]: {
            px: '25px',
        },
    },
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
    },
    closeBtn: {
        position: 'absolute',
        width: '32px',
        min: '32px',
        top: '20px',
        right: '20px',
        cursor: 'pointer',
        pointerEvents: 'auto',
    },
    imageContainer: {
        width: '100%',
        maxWidth:'440px',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center'
    },
};
const FullScreenModal = ({ isOpen, setOpen, image }) => {
    const pathParamas = useParams();
    const collectionName = pathParamas.project;

    return (
        <Modal
            disableScrollLock
            open={isOpen}
            onClose={() => {
                setOpen(false);
            }}
        >
            <Box
                sx={sx.root}
                style={{
                    display: 'flex',
                    jusifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={sx.imageContainer} style={{maxWidth: collectionName === "House of Dead Knights" ? "360px" : "440px"}}>
                    {image && (
                        <img
                            src={image}
                            style={{ width: '100%', borderRadius: '15px' }}
                            alt="modalImage"
                        />
                    )}
                </Box>
                <Box sx={sx.closeBtn} onClick={() => setOpen(false)}>
                    <img src={closenew} style={{ width: '50%' }} alt="Close" />
                </Box>
            </Box>
        </Modal>
    );
};

export default FullScreenModal;
