import HomePage from "../Views/Home/HomePage";
import ProjectPage from "../Views/Projects/ProjectPage";
import React from "react";

//const HomePage = React.lazy(() => import("../Views/Home/HomePage"));
//const ProjectPage = React.lazy(() => import("../Views/Projects/ProjectPage"));

const routes = [{
    path: '/',
    component: <HomePage />,
    exact: true
},
{
    path: '/project/:project',
    component: <ProjectPage />,
    exact: true
}];

export default routes;