import React, { useRef, useState, useEffect, useContext } from "react";
import Title from "../../components/Title";
import Description from "../../components/Description";
import Collections from "./Components/Collections";
import Contact from "../../components/Contact";
import { Box, Typography, Link } from "@mui/material";
import scrollDown from "../../assets/images/scrollUp.svg";
import "./HomePage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {
  l1,
  l,
  l2,
  md,
  md2,
  s,
  s1,
  xs,
  xs2,
} from "../../components/breakPoints";
import CardItem from "./Components/CardItem";
import galaxis from "../../assets/images/galaxis.png";
import { getOnlyTheNamesOfContracts } from "../../nfts/index";
import { getFilteredNames } from "../../nfts/index";
import { getMetaData } from "../../nfts/index";
import LoadingPage from "./Components/LoadingPage";
import { ModeCtx } from "../../context/modeCtx";
import lessIconBlack from "../../assets/images/less-icon-black.svg";
import lessIconWhite from "../../assets/images/less-icon.svg";

const sx = {
  titleDescContainer: {
    width: "100%",
    position: "relative",
  },
  scrollDownIconHolder: {
    position: "relative",
    "& img": {
      transform: "rotate(180deg)",
      position: "absolute",
      cursor: "pointer",
      right: "-12px",
      top: "-240px",
      [md2]: {
        right: "-12px",
        top: "-315px",
      },
      [md]: {
        mr: "25px",
      },
      [s]: {
        top: "-333px",
      },
      [xs]: {
        width: "17%",
        top: "-380px",
      },
      ["@media (max-width: 370px)"]: {
        mr: "30px",
      },
    },
  },
  desc: {
    width: "calc(100% - 600px)",
    margin: "auto",
    mb: "80px",
    position: "relative",
    zIndex: '5',
    [l1]: {
      width: "calc(100% - 480px)",
    },
    [l2]: {
      width: "calc(100% - 480px)",
    },
    [l]: {
      width: "calc(100% - 228px)",
    },
    [md2]: {
      width: "calc(100% - 160px)",
      mt: "-60px",
    },
    [md]: {
      width: "calc(100% - 110px)",
      mt: "-140px",
    },
    [s1]: {
      mt: "-180px",
    },
    [s]: {
      width: "calc(100% - 48px)",
    },
    [xs2]: {
      mt: "-210px",
    },
    [xs]: {
      "& .contact": {
        width: "100%",
      },
      mt: "-260px",
    },
  },
  slider: {
    width: "calc(100% - 600px)",
    height: "800px",
    margin: "auto",
    zIndex: '0',
    [l1]: {
      width: "calc(100% - 480px)",
    },
    [l2]: {
      width: "calc(100% - 480px)",
    },
    [l]: {
      width: "calc(100% - 228px)",
    },
    [md2]: {
      width: "calc(100% - 165px)",
    },
    [md]: {
      width: "calc(100% - 110px)",
    },
    "& .vertical-dots": {
      [md]: {
        mr: "20px",
      },
      [s]: {
        transform: "rotate(-90deg)",
        top: "50%",
        right: "40%",
        mt: "25px",
      },
      [xs]: {
        right: "45%",
        top: "45%",
      },
    },
    "& .vertical-dots li": {
      [s]: {
        padding: "5px 0",
      },
    },
    [s]: {
      width: "calc(100% - 60px)",
    },
    ["@media (max-width: 450px)"]: {
      width: "100%",
    },
  },
  mobileDescription: {
    overflow: "hidden",
    transition: "all 0.6s",
    color: "#FFFFFFCC",
    fontSize: "16px",
    fontFamily: "segoe-ui",
  },
  showText: {
    fontSize: "14px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: "#FFFFFF",
    mr: "5px",
    //pointerEvents: "all"
  },
};

const Home = () => {
  const collectionsRef = useRef();
  const [headerContractNames, setHeaderContractNames] = useState([]);
  const [loaded, setIsLoaded] = useState(false);
  const [cards, setCards] = useState({});
  const [headerCards, setheaderCards] = useState([]);
  const [mobileView, setMobileView] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const modeCtx = useContext(ModeCtx);

  const camelDescription = " is one of the key partners of the";
  const camelDescription2 =
    " company with many years of experience in the blockchain development. Thanks to them we had the chance to deepdive into the world of NFTs, and together we build the most awesome NFT collections you can ever imagine. Check out their artwork, presentation, and then realize, that most of them have REAL LIFE UTILITIES! Yes, you read it well. With the power of Galaxis’s utility traits we made NFTs even more interactive, more valuable for their communities and gave the possibility to the project owners to reward their community in the most creative ways.";

  useEffect(() => {
    if (window.innerWidth < 600) {
      setMobileView(true);
    }
  }, []);

  const handleShowDescription = () => {
    setShowMore(!showMore);
    //setShowMore((prevState) => !prevState);
  };

  const fetchData = async () => {
    //get only the names of the contracts
    const collectionNames = await getOnlyTheNamesOfContracts();

    //get an object with keys of the names of the collections and values of the tokenURIs
    const metas = await getMetaData();
    setCards(metas);

    //get only 5 collections at the header
    const filteredNames = await getFilteredNames(collectionNames);
    setHeaderContractNames(filteredNames);

    const sliderImages = getHeaderImageFromMetadata(filteredNames, metas);
    setheaderCards(getHeaderImageFromMetadata(filteredNames, metas));
    setIsLoaded(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getHeaderImageFromMetadata = (filteredNames, metas) => {
    let metadataUriArray = [];
    Object.keys(metas).map((collection) => {
      filteredNames.map((name) => {
        if (collection === name) {
          metadataUriArray.push(metas[collection][0]);
        }
      });
    });
    return metadataUriArray;
  };

  const scrollToCollections = (collectionsRef) => {
    window.scrollTo({
      left: 0,
      top: collectionsRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };

  const SimpleSlider = () => {
    var settings = {
      dots: true,
      fade: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dotsClass: "vertical-dots",
    };
    return (
      <Slider {...settings} sx={sx.simpleSlider}>
        <div className="slide-container">
          <Title title={headerContractNames[0].toUpperCase()}></Title>
          <img src={galaxis} alt="image" className="galaxis" />
        </div>
        <div className="slide-container">
          <Title title={headerContractNames[3].toUpperCase()}></Title>
          {/* <img className="card-img" src={headerCards[0]} /> */}
          <CardItem uri={headerCards[1]} className="card-img" />
        </div>
        <div className="slide-container">
          <Title title={headerContractNames[1].toUpperCase()}></Title>
          <CardItem uri={headerCards[4]} className="card-img" />
        </div>
        <div className="slide-container">
          <Title title={headerContractNames[2].toUpperCase()}></Title>
          <CardItem uri={headerCards[0]} className="card-img" />
        </div>
        <div className="slide-container">
          <Title title={headerContractNames[5].toUpperCase()}></Title>
          <CardItem uri={headerCards[3]} className="card-img" />
        </div>
        <div className="slide-container">
          <Title title={headerContractNames[4].toUpperCase()}></Title>
          <CardItem uri={headerCards[2]} className="card-img" />
        </div>
      </Slider>
    );
  };

  return (
    <>
      {loaded ? (
        <>
          {headerContractNames.length > 0 ? (
            <Box sx={sx.slider}>
              <SimpleSlider />
              <Box sx={sx.scrollDownIconHolder}>
                <img
                  className="scroll-down-arrow"
                  src={scrollDown}
                  alt="scroll down icon"
                  onClick={() => scrollToCollections(collectionsRef)}
                />
              </Box>{" "}
            </Box>
          ) : (
            ""
          )}
          <Box sx={sx.desc}>
            {!mobileView ? (
              <>
                {" "}
                <Description
                  type="home"
                  description={camelDescription}
                  description2={camelDescription2}
                ></Description>
              </>
            ) : (
              <Box>
                <Typography
                  sx={sx.mobileDescription}
                  style={{
                    maxHeight: showMore ? "unset" : "112px",
                  }}
                >
                  <Description
                    type="home"
                    description={camelDescription}
                    description2={camelDescription2}
                  ></Description>
                </Typography>
                <Typography onClick={handleShowDescription} sx={sx.showText}>
                {showMore ? "See less" : "See more"}
                 
                  <img
                    src={modeCtx.lightTheme ? lessIconBlack : lessIconWhite}
                    alt="arrow"
                    style={{
                      transform: showMore ? "rotate(0deg)" : "rotate(180deg)",
                      marginLeft: "5px",
                      // marginRight: smallMobile ? "-10px" : "2px",
                    }}
                  />
                </Typography>
              </Box>
            )}
          </Box>

          <Box ref={collectionsRef}>
            <Collections nftCollections={cards}></Collections>
          </Box>
          <Contact onIconClicked={() => scrollToTop()}></Contact>
        </>
      ) : (
        <LoadingPage />
      )}
    </>
  );
};

export default Home;
