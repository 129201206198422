import { Box, Typography } from "@mui/material";
import React, {useState, useEffect, useContext} from "react";
import { md2 } from "./breakPoints";
import { ModeCtx } from "../context/modeCtx";

const sx = {
  singleColumn: {
    columnCount: "1",
  },
  doubleColumn: {},
  descSolo: {
    // maxWidth: "50%",
    [md2]: {
      maxWidth: "800px",
    },
  },
  descriptions: {
    columnGap: "34px",
    margin: "auto",
    fontFamily: "segoe-ui",
  },
};

const Description = ({ type, description, description2 }) => {
  const [mobileView, setMobileView] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const modeCtx = useContext(ModeCtx);

  useEffect(() => {
    if (window.innerWidth < 600 && window.innerWidth > 325) {
      setMobileView(true);
    }
  }, []);

const handleShowChanges = () => {
  setShowMore(!showMore);
};

  const galaxisLink = (
    <a href="https://galaxis.xyz/" target="_blank" className="contact">
      Galaxis
    </a>
  );
  const camelCodingLink = (
    <a href="https://www.camelcoding.com/" target="_blank" className="contact">
      CamelCoding
    </a>
  );

  return (
    <Box>
      <Box
        className="description"
        sx={type === "project" ? sx.descriptions : sx.descSolo}
        style={{     
        fontFamily: "segoe-ui",
        color: modeCtx.lightTheme ? "black" :  "rgba(255, 255, 255, 0.8)",
        lineHeight: "28px",
        fontWeight: "400",
        fontSize: "18px",
          columnCount:
            type === "project" && description.length > 300 ? "2" : "1",
          maxWidth:
            // type === 'project' && description.length > 300 ? '100%' : '800px',
            description.length > 300
              ? type === "project" && "100%"
              : type === "home" && "600px",
        }}
      >
    
        {type === "home" ? (
          <>
            <p className="contact">{galaxisLink}</p>
            {description}
            <p className="contact"> {camelCodingLink}</p>
            <p style={{ display: "inline" }}>{description2}</p>
            <p varriant="body1" style={{ maxWidth: "600px", marginTop: "0"  }}>
              {" "}
              Below we listed the NFTs that we own from these collections. Have
              a look around, and if you have questions, feel free to{" "}
              <span
                className="contact"
                onClick={() =>
                  window.open("mailto:hello@camelcoding.com", "_blank")
                }
                style={{ textDecoration: "underline", cursor: "pointer", color: "#00d5ff", WebkitTextStroke: 'transparent'}}
              >
                contact us.
              </span>
            </p>
          </>
        ) : (
          <>{description}</>
        )}
      </Box>
    </Box>
  );
};

export default Description;
