import { createContext, useState, useEffect } from "react";

export const ModeCtx = createContext({
  lightTheme: false,
  setLightTheme: () => {},
});

const ModeCtxProvider = (props) => {
  let storageItem = JSON.parse(localStorage.getItem('lightMode'));
  const [lightMode, setLightMode] = useState(storageItem);

  const toggleLightMode = () => {
    setLightMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    if (!lightMode) {

      document
        .querySelectorAll(".light")
        .forEach((e) => e.classList.remove("light"));
      document.body.classList.add("dark");
      localStorage.setItem('lightMode', false)
    } else {
      document
        .querySelectorAll(".dark")
        .forEach((e) => e.classList.remove("dark"));
      document.body.classList.add("light");
      localStorage.setItem('lightMode', true)
    }
  }, [lightMode]);

  return (
    <ModeCtx.Provider
      value={{ lightTheme: lightMode, setLightTheme: toggleLightMode }}
    >
      {props.children}
    </ModeCtx.Provider>
  );
};

export default ModeCtxProvider;
