
export const l1 = '@media (max-width: 1700px)';
export const l2 = '@media (max-width: 1580px)';
export const l = '@media (max-width: 1440px)';
export const md1 = '@media (max-width: 1250px)';
export const md2 = '@media (max-width: 1150px)';
export const md = '@media (max-width: 1000px)';
export const s1 = '@media (max-width: 750px)';
export const s = '@media (max-width: 700px)';
export const xs1 = '@media (max-width: 650px)';
export const xs2 = '@media (max-width: 500px)';
export const xs = '@media (max-width: 400px)';
