import React, { useEffect, useState, useContext } from "react";
import Typography from "@mui/material/Typography";
import Social from "./Social";
import Box from "@mui/material/Box";
import Description from "../../../components/Description";
import backArrow from "../../../assets/images/back.svg";
import { useNavigate } from "react-router-dom";
import { l, md, s, xs, xs2 } from "../../../components/breakPoints";
import lessIconWhite from "../../../assets/images/less-icon.svg";
import lessIconBlack from "../../../assets/images/less-icon-black.svg";
import { ModeCtx } from "../../../context/modeCtx";

const BP1 = "@media (max-width: 600px)";

const sx = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleHolder: {
    width: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    gap: "24px",
    [s]: {
      flexDirection: "column",
      alignItems: "unset",
    },
    "& img": {
      cursor: "pointer",
      width: "64px",
      height: "64px",
      mr: "15px",
      [BP1]: {
        width: "40px",
        height: "40px",
        mr: "0",
      },
    },
  },
  title: {
    textTransform: "uppercase",
    fontSize: "72px",
    margin: "auto",
    paddingRight: "64px",
    [l]: {
      fontSize: "62px",
    },
    [md]: {
      fontSize: "50px",
    },
    [s]: {
      pr: "0px",
    },
    [xs2]: {
      fontSize: "40px",
    },
    [xs]: {
      fontSize: "30px",
    },
  },
  subtitle: {
    fontSize: "72px",
    lineHeight: "97px",
    textAlign: "center",
    letterSpacing: "0.03em",
    [l]: {
      fontSize: "60px",
      lineHeight: "50px",
    },
    [md]: {
      fontSize: "50px",
    },
    [xs2]: {
      fontSize: "40px",
    },
    [xs]: {
      fontSize: "24px",
    },
  },
  descriptionHolder: {
    width: "calc(100% - 208px)",
    mt: "40px",
    mb: "80px",
    //maxWidth: "808px",
    [l]: {
      width: "calc(100% - 208px)",
    },
    [md]: {
      width: "calc(100% - 160px)",
    },
    [s]: {
      width: "calc(100% - 48px)",
    },
    [xs]: {
      width: "calc(100% - 24px)",
    },
  },
  mobileDescription: {
    overflow: "hidden",
    transition: "all 0.6s",
    color: "#FFFFFFCC",
    fontSize: "16px",
    fontFamily: "segoe-ui",
  },
  showText: {
    fontSize: "14px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: "#FFFFFF",
  },
};

const ProjectPageHeader = ({ title, openseaDatas }) => {
  const navigate = useNavigate();
  const [mobileView, setMobileView] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [smallMobile, setSmallMobile] = useState(false);
  const modeCtx = useContext(ModeCtx);

  useEffect(() => {
    if (window.innerWidth < 600 && window.innerWidth > 325) {
      setMobileView(true);
    } else if (window.innerWidth < 325) {
      setMobileView(true);
      setSmallMobile(true);
    }
  }, []);

  const handleShowChanges = () => {
    setShowMore(!showMore);
  };
  
  const handleGoBack = () => {
    navigate("/", { replace: true });
  };

  return (
    <>
      {openseaDatas && (
        <Box sx={sx.root}>
          <Box sx={sx.titleHolder}>
            <img
              src={backArrow}
              alt="back"
              className="go-back-img-button"
              onClick={handleGoBack}
            />
            <Typography variant="body2" sx={sx.title}>
              {title}
            </Typography>
          </Box>
          <Typography variant="body3" sx={sx.subtitle}>
            NFTS
          </Typography>

          <Social
            discordLink={
              openseaDatas.collection.discord_url !== null &&
              openseaDatas.collection.discord_url !== undefined
                ? openseaDatas.collection.discord_url
                : undefined
            }
            twitterLink={
              openseaDatas.collection.twitter_username !== null &&
              openseaDatas.collection.twitter_username !== undefined
                ? "https://twitter.com/" +
                  openseaDatas.collection.twitter_username
                : undefined
            }
            webLink={
              openseaDatas.collection.external_url !== null &&
              openseaDatas.collection.external_url !== undefined
                ? openseaDatas.collection.external_url
                : undefined
            }
          />

          <Box sx={sx.descriptionHolder}>
            {!mobileView ? (
              <Description
                type="project"
                description={openseaDatas.collection.description}
              ></Description>
            ) : (
              <Box>
                <Typography
                  sx={sx.mobileDescription}
                  style={{
                    maxHeight: showMore ? "unset" : "112px",
                    width: smallMobile ? "280px" : "300px",
                    marginLeft: smallMobile ? "-15px" : 0,
                  }}
                >
                  {openseaDatas.collection.description}
                </Typography>
                <Typography onClick={handleShowChanges} sx={sx.showText}>
                  {showMore ? "See less" : "See more"}
                  <img
                    src={modeCtx.lightTheme ? lessIconBlack : lessIconWhite}
                    alt="arrow"
                    style={{
                      transform: showMore ? "rotate(0deg)" : "rotate(180deg)",
                      marginLeft: "5px",
                      marginRight: smallMobile ? "-10px" : "2px",
                    }}
                  />
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProjectPageHeader;
