import { JsonRpcProvider } from "@ethersproject/providers";
import * as ethers from "ethers";
import { Zoom } from "zoom-next";
import abi from "./contracts/generic-abi.json";
import zoom2 from "./contracts/Zoom2.json";
import polygonZoom2Abi from "./contracts/PolygonZoom2.json";
import polygonAbi from "./contracts/polygon-abi.json";

export const ZOOM2_ADDRESS = "0x7cdF091AF6a9ED75E3192500d3e5BB0f63e22Dea";
export const ZOOM2_ADDRESS_POLYGON =
  "0x40faA8d164973Ab39F3cBf6e3876a3130F9c6C77";

const QUCIKNODE_TOKEN = "11200b9a0f021eb0e380b0f273424505379b271d";
let provider;
let polygonProvider;
export const rpcProvider = () => {
  if (provider === undefined) {
    return (provider = new ethers.providers.JsonRpcProvider(
      `https://quiet-serene-liquid.quiknode.pro/07149f202fb15e87f49291a3255907360e874752/`
    ));
  } else return provider;
};

export const rpcProviderPolygon = () => {
  if (polygonProvider === undefined) {
   return (polygonProvider = new ethers.providers.JsonRpcProvider(
    `https://polygon-mainnet.infura.io/v3/0a0bbd3ce4ea4be5ad706514cf2cd8cc`
  ));
  } else return polygonProvider;
};

export const getZoomContract = () => {
  const zoomContract = new ethers.Contract(
    ZOOM2_ADDRESS,
    zoom2.abi,
    rpcProvider()
  );
  return zoomContract;
};

export const getZoomContractPolygon = () => {
  const zoomContract = new ethers.Contract(
    ZOOM2_ADDRESS_POLYGON,
    polygonZoom2Abi.abi,
    rpcProviderPolygon()
  );
  return zoomContract;
};

export const balanceOfZoom = async (contractAddress, walletAddress) => {
  const zoomLibrary = new Zoom();
  let calls = [];
  contractAddress.forEach((address) => {
    const contract = new ethers.Contract(address, abi.abi, rpcProvider());
    const call = zoomLibrary.addCall(
      contract,
      ["balanceOf", [walletAddress]],
      "balanceOf(address) returns (uint256)"
    );
    calls.push(call);
  });
  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContract().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = {};

  calls.forEach((call, index) => {
    const decoded = zoomLibrary.decodeCall(call)[0];
    const tokenCount = decoded.toNumber();
    if (tokenCount === 0) return;
    result[contractAddress[index]] = tokenCount;
  });
  return result;
};

export const nameOfContractOfZoom = async (contractAddress, walletAddress) => {
  const zoomLibrary = new Zoom();
  let calls = [];
  contractAddress.forEach((address) => {
    const contract = new ethers.Contract(address, abi.abi, rpcProvider());
    const call = zoomLibrary.addCall(
      contract,
      ["name"],
      "name() returns (string)"
    );
    calls.push(call);
  });
  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContract().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = [];

  calls.forEach((call, index) => {
    const decoded = zoomLibrary.decodeCall(call)[0];
    const contractName = decoded.toString();
    //result[contractAddress[index]] = contractName;
    result.push({
      contractAddress: contractAddress[index],
      contractName: contractName,
    });
  });
  return result;
};

export const tokenOfOwnerByIndexZoom = async (
  ownedToknIndexes,
  walletAddress
) => {
  const zoomLibrary = new Zoom();
  let calls = [];
  Object.keys(ownedToknIndexes).forEach((address, index) => {
    const contract = new ethers.Contract(address, abi.abi, rpcProvider());
    for (let i = 0; i < ownedToknIndexes[address]; i++) {
      const call = zoomLibrary.addCall(
        contract,
        ["tokenOfOwnerByIndex", [walletAddress, i]],
        "tokenOfOwnerByIndex(address, uint256) returns (uint256)"
      );
      calls.push({ contractAddress: address, call });
    }
  });
  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContract().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = [];

  calls.forEach((call, index) => {
    try {
      const decoded = zoomLibrary.decodeCall(call.call)[0];
      result.push({
        contractAddress: call.contractAddress,
        tokenId: decoded.toNumber(),
      });
    } catch (error) {
      console.log(`Cannot decode response for ${call.contractAddress}`);
    }
  });
  return result;
};

export const tokenUrisZoom = async (tokens) => {
  const zoomLibrary = new Zoom();
  let calls = [];
  tokens.forEach((token) => {
    const contract = new ethers.Contract(
      token.contractAddress,
      abi.abi,
      rpcProvider()
    );
    const call = zoomLibrary.addCall(
      contract,
      ["tokenURI", [token.tokenId]],
      "tokenURI(uint256) returns (string)"
    );
    calls.push(call);
  });
  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContract().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = [];

  calls.forEach((call, index) => {
    const decoded = zoomLibrary.decodeCall(call)[0].toString();
    result.push({
      ...tokens[index],
      tokenUri: decoded,
    });
  });
  return result;
};

export const getSingleMetada = async (uri) => {
  try {
    const response = await fetch(uri);
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

/////////////////////////////////////////////////////////////////////////polygon///////////////////////////////////////////////////////////

export const balanceOfZoomPolygon = async (contractAddress, walletAddress) => {
  const zoomLibrary = new Zoom();
  let calls = [];
  contractAddress.forEach((address) => {
    const contract = new ethers.Contract(address, polygonAbi.abi, rpcProvider());
    const call = zoomLibrary.addCall(
      contract,
      ["balanceOf", [walletAddress]],
      "balanceOf(address) returns (uint256)"
    );
    calls.push(call);
  });
  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContractPolygon().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = {};

  calls.forEach((call, index) => {
    const decoded = zoomLibrary.decodeCall(call)[0];
    const tokenCount = decoded.toNumber();
    if (tokenCount === 0) return;
    result[contractAddress[index]] = tokenCount;
  });
  return result;
};

export const nameOfContractOfZoomPolygon = async (contractAddress, walletAddress) => {
  const zoomLibrary = new Zoom();
  let calls = [];
  contractAddress.forEach((address) => {
    const contract = new ethers.Contract(address, polygonAbi.abi, rpcProvider());
    const call = zoomLibrary.addCall(
      contract,
      ["name"],
      "name() returns (string)"
    );
    calls.push(call);
  });
  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContractPolygon().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = [];

  calls.forEach((call, index) => {
    const decoded = zoomLibrary.decodeCall(call)[0];
    const contractName = decoded.toString();
    //result[contractAddress[index]] = contractName;
    result.push({
      contractAddress: contractAddress[index],
      contractName: contractName,
    });
  });
  return result;
};

export const tokenOfOwnerByIndexZoomPolygon = async (
  ownedToknIndexes,
  walletAddress
) => {
  const zoomLibrary = new Zoom();
  let calls = [];
  Object.keys(ownedToknIndexes).forEach((address, index) => {
    const contract = new ethers.Contract(address, polygonAbi.abi, rpcProvider());
    for (let i = 0; i < ownedToknIndexes[address]; i++) {
      const call = zoomLibrary.addCall(
        contract,
        ["tokenOfOwnerByIndex", [walletAddress, i]],
        "tokenOfOwnerByIndex(address, uint256) returns (uint256)"
      );
      calls.push({ contractAddress: address, call });
    }
  });
  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContractPolygon().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = [];

  calls.forEach((call, index) => {
    try {
      const decoded = zoomLibrary.decodeCall(call.call)[0];
      result.push({
        contractAddress: call.contractAddress,
        tokenId: decoded.toNumber(),
      });
    } catch (error) {
      console.log(`Cannot decode response for ${call.contractAddress}`);
    }
  });
  return result;
};

export const tokenUrisZoomPolygon = async (tokens) => {
  const zoomLibrary = new Zoom();
  let calls = [];
  tokens.forEach((token) => {
    const contract = new ethers.Contract(
      token.contractAddress,
      polygonAbi.abi,
      rpcProvider()
    );
    const call = zoomLibrary.addCall(
      contract,
      ["tokenURI", [token.tokenId]],
      "tokenURI(uint256) returns (string)"
    );
    calls.push(call);
  });
  const zoomQuery = zoomLibrary.getZoomCall();

  const combinedResult = await getZoomContractPolygon().combine(zoomQuery);
  zoomLibrary.resultsToCache(combinedResult, zoomQuery);

  const result = [];

  calls.forEach((call, index) => {
    const decoded = zoomLibrary.decodeCall(call)[0].toString();
    result.push({
      ...tokens[index],
      tokenUri: decoded,
    });
  });
  return result;
};
