import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Typography,
  Box,
} from "@mui/material";
import CardItem from "./CardItem";
import { useNavigate } from "react-router-dom";
import {
  l,
  l1,
  l2,
  md,
  md1,
  md2,
  s,
  s1,
  xs,
  xs1,
  xs2,
} from "../../../components/breakPoints";
import { ModeCtx } from "../../../context/modeCtx";

const bp1 = ["@media (max-width: 950px)"];

const sx = {
  row: {
    "&:first-of-type td, &:first-of-type th": { borderTop: "2px solid #00D5FF" },
    "&:hover": { background: "rgba(255, 255, 255, 0.07)" },
    [xs]: {
      "&:hover":"none",
    }
  },
  cell: {
    borderBottom: "2px solid #00D5FF",
    display: "flex",
    justifyContent: "space-between", 
    alignItems: "center",
    // gap: "132px",
    cursor: "pointer",
    p: "16px 0",
    [l1]: {
      gap: "30px",
      // width: "calc(100% - 280px)",
    },
    [l2]: {
      //width: "calc(100% - 380px)",
      // width: '100%',
    },
    [l]: {
      gap: "30px",
    },
    [bp1]: {
      display: "block",
    },
    [s1]: {
      display: "block",
    },
    [s]: {
      display: "block",
    },
  },
  collectionTitle: {
    marginLeft: "300px",
    fontSize: "50px",
    maxWidth: "950px",
    [l1]: {
      // ml: "305px",
      ml: "240px",
      fontSize: "45px",
      maxWidth: '950px'
    },
    [l2]: {
      ml: "240px",
      maxWidth: '950px'
    },
    [l]: {
      marginLeft: "110px",
      fontSize: "45px",
      maxWidth: '850px'
    },
    [md1]: {
      fontSize: "40px",
      letterSpacing: "0",
    },
    [md2]: {
      marginLeft: "70px",
      letterSpacing: "0",
      maxWidth: '650px'
    },
    ["@media (max-width: 1100px)"]: {
      marginLeft: "65px",
      fontSize: "35px",
      letterSpacing: "0",
    },
    [md]: {
      marginLeft: "55px",
      fontSize: "35px",
      maxWidth: '650px',
      letterSpacing: "0",
      mb: '25px'
    },
    [s1]: {
      marginLeft: "50px",
      fontSize: "33px",
    },
    [s]: {
      marginLeft: "30px",
      fontSize: "30px",
    },
    [xs1]: {
      width: "max-content",
      fontSize: "27px",
    },
    [xs2]: {
      maxWidth: '430px',
      fontSize: "24.5px",
      mr: '30px'
    },
    [xs]: {
      fontSize: "22px",
      marginLeft: "20px",
      maxWidth: '330px',
    },
    ["@media (max-width: 350px)"]: {
      maxWidth: '300px'
    },
  },
  cardHolder: {
    display: "flex",
    justifyContent: "flex-start",
    marginRight: "275px",
    gap: "10px",
    [l1]: {
      mr: "218px",
    },
    [l2]: {
      mr: "220px",
    },
    [l]: {
      marginRight: "98px",
    },
    // [md1]: {
    //   marginRight: "100px",
    // },
    [md2]: {
      mr: "80px",
    },
    ["@media (max-width: 1100px)"]: {
      mr: "70px",
    },
    [md]: {
      marginRight: "50px",
    },
    [bp1]: {
      ml: "50px",
    },
    [s1]: {
      marginLeft: "50px",
    },
    [s]: {
      marginLeft: "30px",
    },
    [xs]: {
      mr: "20px",
      ml: "20px",
    },
    "& img": {
      // opacity: "0.5",
      // borderRadius: "10px",
      // maxWidth: "80px",
      // maxHeight: "113px",
      // filter: "grayscale(90%)",
      [l2]: {
        maxWidth: "77px",
        maxHeight: "110px",
      },
      [md2]: {
        maxWidth: "75px",
        maxHeight: "108px",
      },
      ["@media (max-width: 360px)"]: {
        maxWidth: "65px",
        maxHeight: "98px",
      },
    },
  },
  nfts: {
    fontSize: "50px",
    [l1]: {
      fontSize: "45px",
    },
    [l]: {
      fontSize: "45px",
    },
    [md1]: {
      fontSize: "37px",
    },
    ["@media (max-width: 1100px)"]: {
      fontSize: "35px",
      letterSpacing: "0",
    },
    [md]: {
      fontSize: "33px",
    },
    [s1]: {
      fontSize: "33px",
    },
    [s]: {
      fontSize: "28px",
    },
    [xs2]: {
      WebkitTextStroke: "0.5px #FFFFFF",
      fontSize: "24px",
    },
    [xs]: {
      fontSize: "21px",
    },
  },
  titleBox: {
    display: "flex",
    flexDirection: "row",
    gap: "23px",
    alignItems: "center",
    [md1]: {
      gap: "15px",
    },
    [md]: {
      gap: "15px",
    },
    [s]: {
      flexWrap: "wrap",
      mb: "14px",
    },
    [xs1]: {
      width: "min-content",
      gap: "0",
    },
    [xs2]: {
      width: "min-content",
      gap: "0",
    },
  },
  container: {
    overflowX: "hidden",
  },
};

const Collections = ({ nftCollections }) => {
  const navigate = useNavigate();
  const modeCtx = useContext(ModeCtx);

  const [numberOfCards, setNumberOfCards] = useState(0);

  const handldeNavigation = (title) => {
    navigate(`/project/${title}`);
  };

  function debounce(func, wait = 25, immediate = true) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  const tableRows = document.querySelectorAll(".row");

  const checkRowPosition = () => {
    if (window.innerWidth <= 700) {
      let windowTop = window.scrollY;
      let windowMiddle = windowTop + window.innerHeight / 2;

      tableRows.forEach((row) => {
        //how far is the image top from down
        const boundingYproductElementTop = row.getBoundingClientRect().y + window.scrollY;
        //how far is the image bottom from down
        const boundingYproductElementBottom = boundingYproductElementTop + row.offsetHeight;

        if (boundingYproductElementTop <= windowMiddle && boundingYproductElementBottom >= windowMiddle) {
          row.classList.add("middle");
          //console.log("middle added");
          if (modeCtx.lightTheme) {
            row.classList.add("middleLight");
            //console.log("middle light added");
          }
        } else {
          row.classList.remove("middle");
          if (modeCtx.lightTheme) {
            row.classList.remove("middleLight");
          }
        }
      });
    }
  };

  //window.addEventListener("scroll", debounce(checkRowPosition));

  useEffect(() => {
    if (window.innerWidth < 1150 && window.innerWidth > 950) {
      setNumberOfCards(3);
    } else {
      setNumberOfCards(4);
    }
  }, []);

  return (
    <TableContainer sx={sx.container}>
      <Table>
        <TableBody>
          {nftCollections &&
            Object.keys(nftCollections).map((collection) => (
              <tr
                key={collection}
                // sx={sx.row}
                className="row"
                onClick={() => {
                  handldeNavigation(collection);
                }}
              >
                <TableCell component="td" scope="row" sx={sx.cell} key={collection.id}>
                  <Box sx={sx.titleBox}>
                    <Typography sx={sx.collectionTitle} variant="body2">
                      {collection.toUpperCase()}
                      <Typography className="nftsSubtitle" variant="body3" sx={sx.nfts}> NFTS</Typography>
                    </Typography> 
                  </Box>
                  <Box sx={sx.cardHolder}>
                    {nftCollections[collection].map((uri, index) => {
                      if (index < numberOfCards) {
                        return <CardItem uri={uri} className="nft" key={uri}></CardItem>;
                      } else {
                        return;
                      }
                    })}
                  </Box>
                </TableCell>
              </tr>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Collections;
