import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { getSingleMetada } from "../../../nfts/contractMethods";
import fullscreen from "../../../assets/images/fullscreen.svg";
import fullscreenBlack from "../../../assets/images/fullscreen-black.svg";
import FullScreenModal from "./FullScreenModal";
import { l, l2, md1, md2, s, xs, xs2 } from "../../../components/breakPoints";
import { ModeCtx } from "../../../context/modeCtx";

const sx = {
  holder: {
    maxWidth: "392px",
    display: "flex",
    flexDirection: "column",
  },
  backgroundBox: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    borderRadius: "12px",
    "& iframe": {
      width: "392px",
      height: "392px",
      borderRadius: "12px",
      [l2]: {
        width: "350px",
        height: "350px",
      },
      [md1]: {
        width: "300px",
        height: "300px",
      },
      [md2]: {
        width: "280px",
        height: "280px",
      },
      ["@media (max-width: 1060px)"]: {
        width: "260px",
        height: "260px",
      },
      [xs2]: {
        width: "300px",
        height: "300px",
      },
      ["@media (max-width: 350px)"]: {
        width: "280px",
        height: "280px",
      },
      ["@media (max-width: 300px)"]: {
        width: "260px",
        height: "260px",
      }
    },
  },
  backgroundBoxImage: {
    width: "100%",
    height: "91.5%",
    backgroundColor: "#00001E",
    textAlign: "center",
    borderRadius: "12px",
    "& img": {
      height: "392px",
      borderRadius: "12px",
      paddingBottom: "0px",
      [l2]: {
        height: "300px",
      },
      [md1]: {
        height: "280px",
      },
      ["@media (max-width: 1060px)"]: {
        height: "280px",
      },
      [xs2]: {
        height: "280px",
      },
      [xs]: {
        height: "240px",
      },
    },
  },
  names: {
    fontSize: "24px",
    maxWidth: "330px",
    mr: "32px",
    lineHeight: "32px",
    [md2]: {
      width: "200px",
    },
    [md2]: {
      width: "200px",
    },
    [s]: {
      fontSize: "24px",
    },
    [xs]: {
      fontSize: "22px",
    },
  },
  nameHolder: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "392px",
    [l2]: {
      width: "350px",
    },
    [md1]: {
      width: "300px",
    },
    [md2]: {
      width: "280px",
    },
    ["@media (max-width: 1060px)"]: {
      width: "260px",
    },
    [xs2]: {
      width: "300px",
    },
    ["@media (max-width: 350px)"]: {
      width: "280px",
    },
    ["@media (max-width: 300px)"]: {
      width: "260px",
    },
    "& img": {
      cursor: "pointer",
    },
  },
  imageNameHolder: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [l2]: {
      width: '300px',
    },
    [md1]: {
      width: "280px",
    },
    ['@media (max-width: 1060px)']: {
      width: "280px",
    },
    [xs2]: {
      width: "280px",
    },
    [xs]: {
      width: "240px",
    },
    "& img": {
      cursor: "pointer",
    },
  },
  outer: {
    display: "flex",
    flexDirection: "column",
  },
};

const Card = ({ uri }) => {
  const [meta, setMeta] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const modeCtx = useContext(ModeCtx);
  const modalOpen = () => {
    setModalIsOpen(true);
  };

  const getMetaData = async () => {
    const data = await getSingleMetada(uri);
    setMeta(data);
  };
  useEffect(() => {
    getMetaData();
  }, [uri]);

  return (
    <>
      {meta && (
        <>
          <Box sx={sx.holder}>
            <Box
              sx={
                meta.animation_url && meta.animation_url !== meta.image
                  ? sx.backgroundBox
                  : sx.backgroundBoxImage
              }
            >
              {meta.animation_url && meta.animation_url !== meta.image ? (
                <iframe
                  src={meta.animation_url}
                  alt="card"
                  frameBorder={0}
                  scrolling="no"
                  loading="lazy"
                  className="iframeNeeded"
                ></iframe>
              ) : (
                <img src={meta.image} alt="card"></img>
              )}
            </Box>

            <Box
              sx={
                meta.animation_url && meta.animation_url === meta.image
                  ? sx.imageNameHolder
                  : sx.nameHolder
              }
            >
              <Typography variant="body2" sx={sx.names}>
                {meta.name}
              </Typography>
              <img
                src={modeCtx.lightTheme ? fullscreenBlack : fullscreen}
                alt="fullscreen icon"
                onClick={modalOpen}
              />
            </Box>
          </Box>
        </>
      )}

      {meta && (
        <FullScreenModal
          image={meta.image}
          isOpen={modalIsOpen}
          setOpen={setModalIsOpen}
        />
      )}
    </>
  );
};

export default Card;
