import { Box } from "@mui/material";
import React from "react";
import Cards from "./Components/Cards";
import Contact from "../../components/Contact";
import ProjectPageHeader from "./Components/ProjectPageHeader";
import { useState, useEffect } from "react";
import "./ProjectPage.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import LoadingSpinner from "../../components/LoadingSpinner";
import { l, md, s, xs, l2, md2 } from "../../components/breakPoints.js";
import { getContractNames } from "../../nfts/index";
import { getMetaData } from "../../nfts";

const sx = {
  root: {
    width: "calc(100% - 600px)",
    height: "100%",
    margin: "auto",
    mt: "40px",
    [l2]: {
      width: "calc(100% - 480px)",
    },
    [l]: {
      width: "calc(100% - 228px)",
    },
    [md2]: {
      width: "calc(100% - 160px)",
    },
    [md]: {
      width: "calc(100% - 110px)",
    },
    [s]: {
      width: "calc(100% - 48px)",
    },
  },
};

const ProjectPage = () => {
  const collectionName = useParams();
  const [loaded, setIsLoaded] = useState(false);
  const [cards, setCards] = useState(null);
  const [openseaProjectInfos, setopenseaProjectInfos] = useState({});

  const scrollToTop = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchData = async () => {
    //get the names of the contracts
    console.log("belejon")
    const nameOfContracts = await getContractNames();
    //filter the array of names to avoid duplicates
    const filteredContractNames = nameOfContracts.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.contractName === value.contractName)
    );

    //get an object with keys of the names of the collections and values of the tokenURIs
    const metas = await getMetaData();
    setCards(metas);

    //get opensea data, description is important for us
    filteredContractNames.map((item) => {
      if (item.contractName === collectionName.project) {
        axios
          .get(
            `${
              "https://api.opensea.io/api/v1/asset_contract/" +
              item.contractAddress
            }`
          )
          .then((response) => {
            setopenseaProjectInfos(response.data);
            setIsLoaded(true);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    });
  };

  if(collectionName.project === "House of Dead Knights"){
    localStorage.setItem("collectionName", collectionName.project);
  }

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loaded ? (
        openseaProjectInfos.collection &&
        cards[collectionName.project] && (
          <>
            <Box sx={sx.root}>
              <ProjectPageHeader
                title={collectionName.project}
                openseaDatas={openseaProjectInfos}
              />
              <Cards uris={cards[collectionName.project]} />
            </Box>
            <Contact onIconClicked={() => scrollToTop()}></Contact>
          </>
        )
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default ProjectPage;
