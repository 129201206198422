import React from "react";
import { Typography, Box } from "@mui/material";
import scrollUp from "../assets/images/scrollUp.svg";
import { l, l1, l2, md, md2, s } from "./breakPoints";

const sx = {
  contact: {
    color: "#00D5FF",
    fontWeight: "400",
    fontSize: "16px",
    margin: "auto",
    cursor: "pointer",
  },
  footer: {
    position:'relative',
    display: "flex",
    justifyContent: "flex-end",
    width: "calc(100% - 600px)",
    margin: "auto",
    marginTop: "141px",
    marginBottom: "62px",
    [l1]: {
      width: "calc(100% - 550px)",
    },
    [l2]: {
      width: "calc(100% - 480px)",
    },
    [l]: {
      width: "calc(100% - 240px)",
    },
    [md2]: {
      width: "calc(100% - 180px)",
    },
    [md]: {
      width: "calc(100% - 130px)",
    },
    [s]: {
      width: "calc(100% - 60px)",
    }
  },
};

const Contact = (props) => {
  const openMail = () => {
    window.open('mailto:hello@camelcoding.com', '_blank')
  };

  return (
    <>
      <Box sx={sx.footer}>
        <Typography
          variant="h6"
          sx={sx.contact}
          onClick={openMail}
        >
          CONTACT US
        </Typography>
        <img
          src={scrollUp}
          alt="scroll to top"
          className="scroll-to-top"
          onClick={props.onIconClicked}
        ></img>
      </Box>
    </>
  );
};

export default Contact;
