import { Typography } from "@mui/material";
import React from "react";
import { l, md, md1, s, s1, xs, xs1, xs2 } from "./breakPoints";
const BP1 = ['@media (max-width: 375px)']
const sx = {
  title: {
    letterSpacing: "0",
    zIndex: 1,
    lineHeight: '112px',
    // mb: "8px",
    mt: '160px',
    [l]: {
      fontSize: "100px",
    },
    [md]: {
      fontSize: "70px",
      lineHeight: '80px'
    },
    [s1]: {
      fontSize: "70px",
    },
    [s]: {
      fontSize: '60px',
      mt: '60px'
    },
    [xs1]: {
      fontSize: "50px",
      lineHeight: '50px'
    },
    [xs2]: {
      fontSize: "40px",
    },
    [xs]: {
      fontSize: "33px",
      lineHeight: '35px'
    },
   [BP1]: {
    fontSize: "28px",
    lineHeight: '30px'
   }
  },
  outlinedTile: {
    letterSpacing: "0.03em",
    zIndex: 1,
    lineHeight: '110px',
    [l]: {
      fontSize: "100px",
    },
    [md]: {
      fontSize: "70px",
      lineHeight: '75px',
      WebkitTextStroke: "0.4px #FFFFFF",
    },
    [s1]: {
      fontSize: "70px",
    },
    [s]: {
      fontSize: '60px',
    },
    [xs1]: {
      fontSize: "50px",
      lineHeight: '45px'
    },
    [xs2]: {
      fontSize: "40px",
    },
    [xs]: {
      fontSize: "35px",
      lineHeight: '37px'
    },
    [BP1]: {
      fontSize: "30px",
      lineHeight: '28px'
     }
  },
};

const Title = ({ title }) => {
  return (
    <>
      <div>
        <Typography variant="body2" sx={sx.title}>
          {title}
        </Typography>
        <Typography variant="body3" sx={sx.outlinedTile}>
          {title === "CAMELCODING X GALAXIS" ? "" : "NFTS"}
        </Typography>
      </div>
    </> 
  );
};

export default Title;
