import "./App.css";
import Header from "./components/Header";
import theme from "./theme/Theme";
import { ThemeProvider } from "@mui/material";
import routes from "./routes/Routes.js";
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
  HashRouter,
} from "react-router-dom";
import ModeCtxProvider from "./context/modeCtx";
import { Suspense, useEffect } from "react";
import LoadingPage from "./Views/Home/Components/LoadingPage";
import LoadingSpinner from "./components/LoadingSpinner";

const App = () => {
  useEffect(() => {
    let storageItem = JSON.parse(localStorage.getItem("lightMode"));

    if (storageItem) {
      document
        .querySelectorAll(".dark")
        .forEach((e) => e.classList.remove("dark"));
      document.body.classList.add("light");
    } else {
      document
        .querySelectorAll(".light")
        .forEach((e) => e.classList.remove("light"));
      document.body.classList.add("dark");
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ModeCtxProvider>
        {/* <Suspense> */}
          <HashRouter basename="/">
            <Header></Header>
            <Routes>
              {routes.map(({ path, component, exact }) => (
                <Route
                  key={path}
                  path={path}
                  exact={exact}
                  element={component}
                />
              ))}
            </Routes>
          </HashRouter>
        {/* </Suspense> */}
      </ModeCtxProvider>
    </ThemeProvider>
  );
};

export default App;
