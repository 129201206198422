export const contractAddresses= [
  "0x0847d1fb2adc3d1c19b233afb7d01a631728a415",
  "0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8",
  "0x360e63c77ae3bbad546e85d2f7ba7e6c98a2abfe",
    "0x2a3bc72ed71db2a27cfe2ba50aecc692fb04fcff",
    "0x3f691327267993cfe4842ca1364a52dfe6190ec1",
    "0x40fb1c0f6f73b9fc5a81574ff39d27e0ba06b17b",
    "0x5e5dc4385b87b342a8b982cca1bef8cb5121e737",
    "0x3eac2c4bee7e9972b0d0a5b6dae442cbb23a62b9",
    "0xedec929e45e1fc9d151e2dd2c416af4a43075bfa",
    "0xd6f817fa3823038d9a95b94cb7ad5468a19727fe",
    "0xeaf10ed6e870ab0743a6bfcb9c414ceff63a3f5f",
    "0x66c687c73a1fb42fdf2391fc9da90048189dd97d",
    "0x139b522955d54482e7662927653abb0bfb6f19ba",
    "0x9e5d1c128294237da2ec39e237783a33db48c78b", 
    "0xecec9259df9ea8a1680b4040242c70b85cd0f3f3"
  ]

  export const polygonContractAddresses = [
    "0x3b8a54213a898ce4f04180155c0631e105368913",
    "0x9ab26d93aef3e78c3e220ecb20e769cbd07077c9"
  ]



