import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { getSingleMetada } from "../../../nfts/contractMethods";

const CardItem = ({ uri, className }) => {
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    async function getMetaData() {
      let data = await getSingleMetada(uri);
      setMeta(data);
    }
    getMetaData();
  }, [uri]);

  return (
    <Box>
      {meta && <img src={meta.image} alt="cardImage" className={className ? className : ''}></img>}
    </Box>
  ); 
};

export default CardItem;
