import React from 'react'
import twitter from '../../../assets/images/social/twitter.svg'
import discord from '../../../assets/images/social/discord.svg'
import website from '../../../assets/images/social/website.svg'
import { Box } from '@mui/system'
import { l } from '../../../components/breakPoints'

const sx = {
    socialHolder: {
        display: 'flex',
        gap: '16px',
        justifyContent: 'center',
        '& img': {
            cursor: 'pointer'
        },
        [l]: {
            mt: '20px'
        }
    }
}

const Social = ({ twitterLink, discordLink, webLink }) => {

    const openTwitterLink = () => {
        window.open(twitterLink, '_blank');
    }
    const openDiscordLink = () => {
        window.open(discordLink, '_blank');
    }
    const openWebsiteLink = () => {
        window.open(webLink, '_blank');
    }
    return (
        <Box sx={sx.socialHolder}>
            {twitterLink && <img src={twitter} alt="twitter" onClick={openTwitterLink} />}
            {discordLink && <img src={discord} alt="discord" onClick={openDiscordLink} />}
            {webLink && <img src={website} alt="website" onClick={openWebsiteLink} />}
        </Box>
    )
}

export default Social