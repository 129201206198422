import { Box } from "@mui/material";
import React from "react";
import { xs2 } from "../../../components/breakPoints";
import Card from "./Card";

const BP1 = '@media (max-width: 800px)'

const sx = {
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "auto",
    width: "100%",
    height: "100%",
    [xs2]: {
justifyContent: 'center'
    },
  },
  innerBox: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    rowGap: "98px",
    columnGap: '20px',
    [BP1]: {
      justifyContent: 'center'
    }
  },
};

const Cards = ({ uris }) => {
  return (
    <Box sx={sx.root}>
      {uris && (
        <Box sx={sx.innerBox}>
          {uris.map((uri, index) => {
            return <Card uri={uri} key={index}/>;
          })}
        </Box>
      )}
    </Box>
  );
};

export default Cards;
