import { createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        //description in projects
        body1: {
          fontFamily: "segoe-ui",
          fontSize: "18px",
          color: "rgba(255, 255, 255, 0.8)",
          lineHeight: "28px",
          fontWeight: "400",
        },
        //description on home
        body2: {
          fontFamily: "segoe",
          fontSize: "112px",
          color: "#FFFFFF",
          fontWeight: "900",
          // lineHeight: "100px",
        },
        //outlined letters
        body3: {
          fontFamily: "segoe",
          WebkitTextStroke: "1px #FFFFFF",
          color: "transparent",
          fontSize: "112px",
          fontWeight: "900",
          // lineHeight: "80px",
          letterSpacing: "0.03em",
        },
        body4: {
          fontFamily: "segoe-ui",
          fontSize: "18px",
          color: "rgba(255, 255, 255, 0.8)",
          lineHeight: "28px",
          fontWeight: "400px",
        },
        h6: {
          color: "#00D5FF",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: "400px",
      sm: "700px",
      md: "1000px",
      lg: "1440px",
      xl: "1920px"
    },
  },
});

export default theme;
