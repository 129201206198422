import React from "react";
import Title from "../../../components/Title";
import galaxis from "../../../assets/images/galaxis.png";
import { Box, Typography } from "@mui/material";
import { l, l2, md, md2, s, xs } from "../../../components/breakPoints";
import Description from "../../../components/Description";
//import { camelDescription } from "../../../config/config";
import {s1, xs2 } from "../../../components/breakPoints";

const sx = {
  mainTitle: {
    zIndex: "2",
    maxWidth: '950px',
    [xs]: {
      ml: "30px",
    },
  },
  mainTitleContainer: {
    position: "relative",
  },
  mainTitleHolder: {
    width: "calc(100% - 600px)",
    margin: "auto",
    height: "800px",
    [l2]: {
      width: "calc(100% - 480px)",
    },
    [l]: {
      width: "calc(100% - 228px)",
    },
    [md2]: {
      width: "calc(100% - 165px)",
    },
    [md]: {
      width: "calc(100% - 110px)",
    },
    [s]: {
      width: "calc(100% - 60px)",
    },
    [xs]: {
      width: "100%",
    },
  },
  desc: {
    width: "calc(100% - 600px)",
    margin: "auto",
    mb: "120px",
    mt: '-160px',
    [l2]: {
      width: "calc(100% - 480px)",
    },
    [l]: {
      width: "calc(100% - 228px)",
    },
    [md2]: {
      width: "calc(100% - 160px)",
      mt: "-80px",
    }, 
    [md]: {
      width: "calc(100% - 110px)",
      mt: "-140px",
    },
    [s1]: {
      mt: "-180px",
    },
    [s]: {
      width: "calc(100% - 48px)",
    },
    [xs2]: {
      mt: "-260px",
    },
  },
};

const LoadingPage = () => {
  const camelDescription = " is one of the key partners of the";
  const camelDescription2 =
    " company with many years of experience in the blockchain development. Thanks to them we had the chance to deepdive into the world of NFTs, and together we build the most awesome NFT collections you can ever imagine. Check out their artwork, presentation, and then realize, that most of them have REAL LIFE UTILITIES! Yes, you read it well. With the power of Galaxis’s utility traits we made NFTs even more interactive, more valuable for their communities and gave the possibility to the project owners to reward their community in the most creative ways.";

  return (
    <>
    <Box sx={sx.mainTitleHolder}>
      <Box sx={sx.mainTitleContainer}>
        <Box sx={sx.mainTitle} >
        <Title title="CAMELCODING X GALAXIS"></Title>
        </Box>
        <img src={galaxis} alt="image" className="galaxisLoading" />
      </Box>
      
    </Box>
    <Box sx={sx.desc}>
    <Description type="home" description={camelDescription} description2={camelDescription2}></Description>
    </Box>
    </>
  );
};

export default LoadingPage;
