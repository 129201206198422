import React from "react";
import logoDark from "../assets/images/logoDark.svg";
import logoLight from "../assets/images/logoLight.svg";
import sun from "../assets/images/sun.svg";
import moon from "../assets/images/moon.svg";
import { Box } from "@mui/system";
import { useContext } from "react";
import { ModeCtx } from "../context/modeCtx";
import { useNavigate } from "react-router-dom";
import { l1, l2, l, md, md2, s, xs} from "./breakPoints";

const sx = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "calc(100% - 600px)",
    margin: "auto",
    pt: "40px",
    "& img": {
      cursor: 'pointer',
      [xs]: {

      }
    },
    [l1]: {
      width: "calc(100% - 480px)",
    },
    [l2]: {
      width: "calc(100% - 480px)",
    },
    [l]:{
      width: "calc(100% - 228px)",
    },
    [md2]:{
      width: "calc(100% - 160px)",
    },
    [md]:{
      width: "calc(100% - 110px)",
    },
    [s]: {
      width: "calc(100% - 48px)",
    },
  },
};

const Header = () => {
  const modeCtx = useContext(ModeCtx);
  
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate("/", { replace: true });
  };

  return (
    <Box sx={sx.header}>
      {modeCtx.lightTheme ? (
        <img
          src={logoDark}
          alt="logo"
          className="logo"
          onClick={goToHomePage}
        />
      ) : (
        <img
          src={logoLight}
          alt="logo"
          className="logo"
          onClick={goToHomePage}
        />
      )}

      {modeCtx.lightTheme ? (
        <img
          src={moon}
          alt="sun"
          className="sun"
          onClick={modeCtx.setLightTheme}
        />
      ) : (
        <img
          src={sun}
          alt="sun"
          className="sun"
          onClick={modeCtx.setLightTheme}
        />
      )}
    </Box>
  );
};

export default Header;
